import React from 'react';
import VoucherDistribution from '../../assets/images/voucher-distribution.png';
import HomeDelivery from '../../assets/images/delivery-sampling.png';
import BrandAmbassador from '../../assets/images/ba-program.png';
import GrabnGo from '../../assets/images/grab-n-go.png';
import Gift from '../../assets/images/gift.png';
import Slp from '../../assets/images/slp.png';
import Rlp from '../../assets/images/rlp.png';
import Visibillity from '../../assets/images/visibillity.png';
import { motion } from 'framer-motion';

const data = [
  {
    image: VoucherDistribution,
    title: 'Digital Voucher Distribution',
    subtitle: 'Gain New Users and Cherish Loyal Users Through Price Cut Promotions',
    description: [
      'Plan to recruit new users and create purchase trials? Or perhaps to engage a loyal user to purchase a larger SKU?',
      'Well price cuts might just be the solution you need.',
      'Our Digital Voucher Distribution module will ensure that your price cut promotions would reach consumers, while you collect personal data and unprecedented insights. Explore Digital Voucher Distribution',
    ],
  },
  {
    image: HomeDelivery,
    title: 'Home Delivery Sampling',
    subtitle: 'Send Personalised Samples Straight To Your Consumers Doorsteps',
    description: [
      'Nothing is more memorable than receiving a gift, well in this case a sample. The memories can last a lifetime.',
      'Samples can induce trial which ultimately ends with a purchase.',
      'Our Home Delivery Sampling module will ensure that your samples are sent to the correct consumer persona, and providing you abilities to measure the following sales impact.',
    ],
  },
  {
    image: BrandAmbassador,
    title: 'Brand Ambassador Program',
    subtitle: 'Trace You Brand Ambassadors Performance & Rewards',
    description: [
      'Influencers, Promotion Girls and Frontliners play an important part in growing your business. We understand that.',
      'But how do you actually measure their impact to sales at offline retailers?',
      'Our Brand Ambassador Program module will ensure that you will have full visibility of the sales impact  that your ambassadors generate, thus validating their reward schemes.',
    ],
  },
  {
    image: GrabnGo,
    title: 'Grab N Go Sampling',
    subtitle: 'Cut The Middleman! Deliver Samples Directly Off The Shelf',
    description: [
      "Product sampling is expensive, so they say. It's actually the logistics that makes it that way. So why not cut the slack?",
      'Provide samples to consumers without the middleman.',
      'Our Grab N Go Sampling module will ensure that your profiled consumers can grab a product sample straight from the shelf of the retailer closest to them.',
    ],
  },
  {
    image: Gift,
    title: 'Gift With Purchase',
    subtitle: 'Drive Purchase and Upsizing Urgency through Gifting',
    description: [
      "Now who doesn't like gifts? Increase your basket size and boost your offtake using our GWP Module.",
      'Reward your shopper with gifts based on purchase size or raffle games.',
      'Our Gift With Purchase Module will make sure that you can control gift distribution with extreme details and inventory tracking!',
    ],
  },
  {
    image: Slp,
    title: 'Shopper Loyalty Program',
    subtitle: 'Shopper Loyalty is tough these days, so lets keep it simple!',
    description: [
      'With so much competition in place, it is critical that you maintain your shoppers loyalty, but it has to  be easy to participate in.',
      'Loyalty is about rewarding shoppers who continuously purchase your products.',
      'Our Shopper Loyalty Program is as simple as sending a WhatsApp message. Shoppers simply send the purchase receipt, our AI will handle the rest.',
    ],
  },
  {
    image: Rlp,
    title: 'Retailer Loyalty Program',
    subtitle: 'Make sure you maximize stock levels at stores',
    description: [
      "When you're conducting promotions, the last thing you want would be an OOS condition right? Let's not make shoppers dissapointed.",
      'With this, sweetened deals for retailers must be made!',
      'Our Retail Loyalty Program would help you engage with retailers, track the performance of retailer loyalty programs in real time. Make better and faster decisions!',
    ],
  },
  {
    image: Visibillity,
    title: 'Visibillity & Distribution Drive',
    subtitle: "If your product is not seen, you've already lost the game",
    description: [
      'Visibility is as important as availability. Products with visibility that stands out, have way higher potential to make it off the shelf!',
      "But it's almost impossible to consistently monitor your product display condition right?",
      "Don't worry, our Visibility & Distribution Drive Module is equipped with AI that only requires your frontliners or retailers to send a single display picture and we will handle the rest.",
    ],
  },
];

const OurServices = () => {
  return (
    <div className='max-w-6xl mx-auto py-12'>
      <div className='max-w-3xl mx-auto text-center'>
        <h2 className='text-4xl font-semibold leading-tight'>
          Rediscover powerful ways to deliver consumer and trade promotions
        </h2>
        <p className='mt-6 text-monochrome-400'>
          {
            'Immediately improve your promotions GTM (Go-To Market) through better accuracy, efficiency, effectivity and transparency'
          }
        </p>
      </div>
      <div className='my-10'>
        {data.map((item, index) => (
          <div className='my-8 grid grid-cols-2' key={index}>
            <motion.img
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              src={item.image}
              alt={item.title}
              className={`h-[450px] ${
                (index + 1) % 2 === 0
                  ? 'order-last justify-self-end object-contain'
                  : 'object-cover'
              }`}
            />
            <motion.div
              initial={{ opacity: 0, translateX: index % 2 === 0 ? 100 : -100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.5 }}
              className='flex flex-col gap-5 '
            >
              <p className='font-semibold bg-gradient-to-b from-blue-600 to-purple-600 bg-clip-text text-transparent'>
                {item.title}
              </p>
              <p className='text-3xl leading-tight font-semibold'>{item.subtitle}</p>
              {item.description.map((desc, idx) => (
                <p key={idx} className='text-monochrome-400'>
                  {desc}
                </p>
              ))}

              <div>
                <button className='border-2 border-blue-200 py-2 px-4 rounded-xl flex items-center gap-2'>
                  <p className='bg-gradient-to-r from-blue-600 from-5% via-purple-600 via-50% to-soft-red-500 to-95% text-transparent bg-clip-text font-semibold'>
                    {`Explore ${item.title}`}
                  </p>
                </button>
              </div>
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
