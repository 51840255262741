import React from 'react';
import DefaultLayout from '../../Components/DefaultLayout';
import ImageStudy from '../../assets/images/casestudyimage.jpeg';

const data = {
  title: 'Mabar No Hambar',
  text: 'Mabar No Hambar is an exclusive program conducted by Mayora under the brands of Torabika and Drink Beng-Beng. The program utilises WhatsApp Chatbot Technology to engage university students for participating in their offline games. Registrations for participants are conducted using the Mabar No Hambar Whatsapp at +6281110500506',
};

const Study = () => {
  return (
    <DefaultLayout>
      <div className='min-h-svh max-w-6xl mx-auto py-5'>
        <div className='flex gap-10'>
          <img src={ImageStudy} alt='' className='w-full object-cover rounded-xl' />
          <div>
            <h2 className='text-4xl font-semibold'>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Study;
