import React from 'react';
import { motion } from 'framer-motion';
import Achievemnt1 from '../../assets/images/achievement-2.png';
import Achievemnt2 from '../../assets/images/achievement.png';
import abc from '../../assets/images/abc-logo.png';
import abbott from '../../assets/images/abbott-logo.png';
import kalbe from '../../assets/images/kalbe-logo.png';
import morinaga from '../../assets/images/morinaga-logo.png';
import wyeth from '../../assets/images/wyeth-logo.png';
import mayora from '../../assets/images/mayora-logo.png';
import png from '../../assets/images/p&g-logo.png';
import reckitt from '../../assets/images/reckitt-logo.png';

const data = [
  { icon: abc, duration: 0.1 },
  { icon: abbott, duration: 0.2 },
  { icon: kalbe, duration: 0.3 },
  { icon: morinaga, duration: 0.4 },
  { icon: wyeth, duration: 0.5 },
  { icon: mayora, duration: 0.6 },
  { icon: png, duration: 0.7 },
  { icon: reckitt, duration: 0.8 },
];

const Achievement = () => {
  return (
    <div className='py-16'>
      <div>
        <h3 className='text-3xl font-semibold text-center'>Trusted by leading brands</h3>
        <div className='grid grid-cols-4 max-w-4xl mx-auto gap-x-6 gap-y-8 m-10'>
          {data.map((item, index) => (
            <motion.img
              key={index}
              src={item.icon}
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1, transition: { delay: item.duration } }}
              className='w-full h-24 object-contain'
            />
          ))}
        </div>
      </div>
      <div className='py-7 mt-24 rounded-4xl bg-gradient-to-br from-blue-600 via-purple-600 to-soft-red-600 grid grid-cols-2 gap-3 max-w-5xl mx-auto divide-x divide-monochrome-300 border-4 border-purple-600'>
        <div className='text-monochrome-0 flex gap-5 px-7'>
          <img className='min-w-44 min-h-44 rounded-xl' src={Achievemnt1} alt='achievement' />
          <div className='flex flex-col justify-between w-4/5 gap-3'>
            <div>
              <h3 className='text-xl font-semibold leading-tight mb-3'>
                Awarded Gold Winner by Marketing Interactive in 2024
              </h3>
              <p>For best loyalty program</p>
            </div>
            <p className='font-semibold'>Read More</p>
          </div>
        </div>
        <div className='text-monochrome-0 flex gap-5 px-7'>
          <img className='min-w-44 min-h-44 rounded-xl' src={Achievemnt2} alt='achievement' />
          <div className='flex flex-col justify-between w-4/5 gap-3'>
            <div>
              <h3 className='text-xl font-semibold leading-tight mb-3'>
                Certifed as Mature by CyberVads in 2024
              </h3>
              <p>For data privacy protection</p>
            </div>
            <p className='font-semibold'>Read More</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievement;
