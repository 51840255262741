import React from 'react';
import ChartIcon from '../../assets/icon/chart.svg';
import BulbIcon from '../../assets/icon/bulb.svg';
import ChipIcon from '../../assets/icon/chip.svg';
import MonitorIcon from '../../assets/icon/monitor.svg';
import ImageAsset from '../../assets/images/monitoring-asset.png';
import Scale from '../Animation/Scale';
import Translate from '../Animation/Translate';

const data = [
  {
    icon: ChartIcon,
    label: 'Increase Offtake',
    description: 'See how your promotions would immediately impact offtake',
    position: 'left',
  },
  {
    icon: MonitorIcon,
    label: 'Monitor Budget',
    description: 'Track your promotion budget and avoid admin hassle & fraud',
    position: 'left',
  },
  {
    icon: BulbIcon,
    label: 'Shopper Insight',
    description: 'Understand your shopper profile and behaviour even better',
    position: 'right',
  },
  {
    icon: ChipIcon,
    label: 'Artificial Intelligence',
    description: 'Witness how our AI can better monitor your retail execution',
    position: 'right',
  },
];

const MonitorFeatures = () => {
  return (
    <div className='bg-pattern py-12'>
      <div className='max-w-6xl mx-auto'>
        <div className='px-24 text-center'>
          <h2 className='text-4xl font-semibold my-5'>
            Monitor your consumer & trade promotions like never before
          </h2>
          <p className='px-10'>
            {
              'Through our Portal Kartini Dashboard, you will experience a window to the world of your promotions in real time'
            }
          </p>
        </div>
        <div className='grid grid-cols-4 my-10'>
          <div className='flex flex-col justify-between'>
            {data
              .filter((item) => item.position === 'left')
              .map((item, index) => (
                <div key={index} className='flex flex-col gap-2 items-center text-center'>
                  <Scale rotate duration={0.2}>
                    <div className='bg-purple-50 rounded-lg p-4'>
                      <img src={item.icon} alt='chart' />
                    </div>
                  </Scale>
                  <Translate from='left' duration={0.5}>
                    <p className='font-semibold text-xl'>{item.label}</p>
                    <p className='text-monochrome-300'>{item.description}</p>
                    <p className='bg-gradient-to-r from-purple-600 via-blue-600 to-soft-red-500 text-transparent bg-clip-text font-semibold'>
                      Explore Modules
                    </p>
                  </Translate>
                </div>
              ))}
          </div>
          {/* <div className='justify-self-center bg-gradient-to-br from-blue-50 to-soft-red-100 rounded-xl w-64 h-[30rem]'></div> */}
          <img
            src={ImageAsset}
            alt='Asset'
            className='h-[30rem] w-full col-span-2 object-contain'
          />
          <div className='flex flex-col justify-between'>
            {data
              .filter((item) => item.position === 'right')
              .map((item, index) => (
                <div key={index} className='flex flex-col gap-2 items-center text-center'>
                  <Scale rotate duration={0.2}>
                    <div className='bg-purple-50 rounded-lg p-4'>
                      <img src={item.icon} alt='chart' />
                    </div>
                  </Scale>
                  <Translate from='right' duration={0.5}>
                    <p className='font-semibold text-xl'>{item.label}</p>
                    <p className='text-monochrome-300'>{item.description}</p>
                    <p className='bg-gradient-to-r from-purple-600 via-blue-600 to-soft-red-500 text-transparent bg-clip-text font-semibold'>
                      Explore Modules
                    </p>
                  </Translate>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonitorFeatures;
