import React from 'react';
import casestudyimage from '../../assets/images/casestudyimage.jpeg';

const data = [
  {
    id: 1,
    image: casestudyimage,
    title: 'Mabar No Hambar',
    text: 'Mabar No Hambar is an exclusive program conducted by Mayora under the brands of Torabika and Drink Beng-Beng. The program utilises WhatsApp Chatbot Technology to engage university students for participating in their offline games. Registrations for participants are conducted using the Mabar No Hambar Whatsapp at +6281110500506',
  },
  {
    id: 2,
    image: casestudyimage,
    title: 'Mabar No Hambar',
    text: 'Mabar No Hambar is an exclusive program conducted by Mayora under the brands of Torabika and Drink Beng-Beng. The program utilises WhatsApp Chatbot Technology to engage university students for participating in their offline games. Registrations for participants are conducted using the Mabar No Hambar Whatsapp at +6281110500506',
  },
  {
    id: 3,
    image: casestudyimage,
    title: 'Mabar No Hambar',
    text: 'Mabar No Hambar is an exclusive program conducted by Mayora under the brands of Torabika and Drink Beng-Beng. The program utilises WhatsApp Chatbot Technology to engage university students for participating in their offline games. Registrations for participants are conducted using the Mabar No Hambar Whatsapp at +6281110500506',
  },
];

const CaseStudies = () => {
  return (
    <div className='max-w-6xl mx-auto'>
      <div className='px-20 text-center'>
        <h2 className='text-4xl font-semibold my-5'>Case Studies</h2>
        <p className='px-10'>
          Take a peak on how countless brands have been utilising our services
        </p>
      </div>
      <div className='grid grid-cols-3 my-10 gap-7'>
        {data.map((item) => (
          <div key={item.id} className='flex flex-col gap-5'>
            <img
              src={item.image}
              alt='Case Study'
              className='w-full h-[30rem] object-cover rounded-xl'
            />
            <p className='font-semibold text-lg'>{item.title}</p>
            <p>{item.text.substring(0, 50)}</p>
            <a
              href='/case-study'
              className='bg-gradient-to-r from-purple-600 via-blue-600 to-soft-red-500 text-transparent bg-clip-text font-semibold'
            >
              Checkout Case Study
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudies;
